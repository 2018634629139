// src/components/userBalance.js
import { getDatabase, ref, onValue } from "firebase/database";

export const listenToBalance = (userId, setBalance) => {
    const db = getDatabase();
    const balanceRef = ref(db, `users/${userId}/credits`);

    onValue(balanceRef, (snapshot) => {
        if (snapshot.exists()) {
            const balance = parseFloat(snapshot.val());
            setBalance(parseFloat(balance.toFixed(2))); // Round to two decimal places and update the state
        } else {
            console.log("No balance available.");
            setBalance(0); // Assuming a balance of 0 if none is found
        }
    }, (error) => {
        console.error("Failed to fetch balance:", error);
        setBalance(0); // Handle error by resetting the balance to 0 or some other value
    });
};
