// src/components/ProgressBar.js
import React, { useState, useEffect } from 'react';

const ProgressBar = ({ duration, startProgress, completeImmediately }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!startProgress) return null;

        const interval = duration * 60 * 1000 / 100;
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                if (completeImmediately && prevProgress < 100) {
                    return 100;
                } else if (prevProgress < 100) {
                    return prevProgress + 1;
                } else {
                    clearInterval(timer);
                    return 100;
                }
            });
        }, interval);

        return () => clearInterval(timer);
    }, [startProgress, duration, completeImmediately]);

    if (!startProgress) return null;

    return (
        <div className="progress-bar-container">
            <div className="progress-bar-background">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
                    {progress}%
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
