// translateService.js


// export const getPresignedUrl = async ({ file, user, targetLanguageName }) => {
//     const response = await axios.post('https://ctue105m5h.execute-api.eu-central-1.amazonaws.com/video_processor', {
//         fileName: file.name,
//         fileType: file.type,
//         userAuthId: user.uid,
//         targetLanguage: targetLanguageName,
//     }, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
//     return response.data.url;
// };

// export const uploadFile = async ({ file, presignedUrl }) => {
//     try {
//         await axios.put(presignedUrl, file, {
//             headers: {
//                 'Content-Type': file.type,
//             },
//         });
//         console.log('File upload successful');
//         // You may then proceed with the translation process...
//     } catch (error) {
//         console.error('File upload failed:', error);
//         throw error; // Rethrow to handle in the calling function
//     }
// };


// /src/components/translateService.js
import axios from 'axios';
export const submitVideoUrl = async ({ user, videoUrl, sourceLanguageName, targetLanguageName, paymentData, voice }) => {
    try {
        console.log("voice: ", voice)
        const response = await axios.post('https://ctue105m5h.execute-api.eu-central-1.amazonaws.com/video_processor', {
            userAuthId: user.uid,
            videoUrl: videoUrl,
            sourceLanguage: sourceLanguageName,
            targetLanguage: targetLanguageName,
            paymentData: paymentData,
            voice: voice
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log('sourceLanguageName:', sourceLanguageName);
        console.log('targetLanguageName:', targetLanguageName);

        console.log('Submission successful:', response.data);
        return response.data; // Return the server response
    } catch (error) {
        console.error('Submission failed:', error.response.data);
        throw error.response.data; // Rethrow to handle in the calling function
    }
};

