import React from 'react';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../constants/languageOptions';

const LanguageSelector = ({ targetLanguage, setTargetLanguage, sourceLanguage, setSourceLanguage }) => {
    const { t } = useTranslation();

    return (
        <div className="language-selection-container" style={{ marginTop: '10px', marginRight: '30px' }}>
            <div>
                <label style={{ marginLeft: '80px' }}>{t('translateFrom')}</label>
                <select value={sourceLanguage} onChange={(e) => setSourceLanguage(e.target.value)}>
                    {languageOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <label style={{ marginLeft: '10px' }}>{t('to')}</label>
                <select value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value)}>
                    {languageOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default LanguageSelector;
