import React, { useState, useCallback, useRef, useEffect } from 'react';
import { languageOptions } from './constants/languageOptions';
import { extractLanguageName } from './utils/languageUtils';
import LanguageSelector from './components/LanguageSelector';
import useProcessedZip from './components/processedZips';
import { submitVideoUrl } from './components/translateService';
import ProgressBar from './components/ProgressBar';
import { useTranslation } from 'react-i18next';

function Translate({ user }) {
    const { t } = useTranslation();

    const getLanguageFromCache = (key, defaultLang) => {
        const storedLang = localStorage.getItem(key);
        return storedLang || defaultLang;
    };

    const [sourceLanguage, setSourceLanguage] = useState(() => getLanguageFromCache('sourceLanguage', 'es'));
    const [targetLanguage, setTargetLanguage] = useState(() => getLanguageFromCache('targetLanguage', 'en'));
    const [videoUrl, setVideoUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [startProgress, setStartProgress] = useState(false);
    const [progressComplete, setProgressComplete] = useState(false);
    const [processingDuration, setProcessingDuration] = useState(0);
    const [selectedAudio, setSelectedAudio] = useState("");
    const audioPlayerRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('sourceLanguage', sourceLanguage);
        localStorage.setItem('targetLanguage', targetLanguage);
    }, [sourceLanguage, targetLanguage]);

    const audioFiles = [
        { name: 'Californian', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Falloy.mp3?alt=media&token=cb3cbbcd-ea11-45ad-8976-8aa946325855' },
        { name: 'Classic Chris', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fecho.mp3?alt=media&token=13f50780-320a-4c33-ab34-c0bfb34ce09f' },
        { name: 'Drama Coach', file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Ffable.mp3?alt=media&token=0744df84-7b2c-4ad9-8308-7344181d62a4' },
        { name: "Harmony Holly", file: 'https://firebasestorage.googleapis.com/v0/b/video-translation-react.appspot.com/o/audio%2Fnova.mp3?alt=media&token=e0c10c93-d2fe-438e-9e1e-57745a14ef56' },
        { name: 'The Guardian', file: '/audio/onyx.mp3' },
        { name: 'Deep Diva', file: '/audio/shimmer.mp3' }
    ];

    const handleAudioChange = (event) => {
        const selectedFile = event.target.value;
        setSelectedAudio(selectedFile);
        if (audioPlayerRef.current) {
            audioPlayerRef.current.src = selectedFile;
            audioPlayerRef.current.load();
            audioPlayerRef.current.play()
                .then(() => console.log('Audio playing'))
                .catch(error => {
                    console.error('Error playing audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        }
    };

    const handleReplayNoSound = () => {
        if (!selectedAudio) {
            alert(t('selectVoice'));
        } else {
            audioPlayerRef.current.play()
                .catch(error => {
                    console.error('Error replaying audio:', error);
                    alert(t('playbackFailed', { error: error.message }));
                });
        }
    };

    const handleNewVideo = useCallback((video) => {
        if (video) {
            setProgressComplete(true);
        }
    }, []);

    const finishProgressBar = useCallback(() => {
        setProgressComplete(true);
        setTimeout(() => {
            setStartProgress(false);
            setProgressComplete(false);
        }, 500);
    }, []);

    const handleDownloadZip = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', true);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const zips = useProcessedZip(user, handleNewVideo, finishProgressBar);

    const renderZips = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                {zips.map(zip => (
                    <div key={zip.id} style={{ margin: '20px' }}>
                        <p>
                            {zip.title} - <button onClick={() => handleDownloadZip(zip.url, zip.title)}>{t('download')}</button>
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    const handleTranslate = async () => {
        if (!user) {
            alert(t('pleaseSignIn'));
            return;
        }
        if (!videoUrl) {
            alert(t('enterYoutubeLink'));
            return;
        }

        if (!selectedAudio) {
            alert(t('selectVoiceChoice'));
            return;
        }

        setLoading(true);

        const sourceLanguageName = extractLanguageName(languageOptions.find(option => option.value === sourceLanguage)?.label || '');
        const targetLanguageName = extractLanguageName(languageOptions.find(option => option.value === targetLanguage)?.label || '');
        const voiceName = audioFiles.find(audio => audio.file === selectedAudio)?.name || '';

        try {
            if (videoUrl) {
                const response = await submitVideoUrl({ user, videoUrl, sourceLanguageName, targetLanguageName, voice: voiceName });
                setProcessingDuration(response.video_duration / 1);
                setStartProgress(true);
                setProgressComplete(false);
            }
        } catch (error) {
            console.error("Error:", error);

            if (error.error === 'Insufficient credits') {
                alert(t('insufficientCredits'));
            } else {
                alert(error.error);
            }
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    return (
        
        <div className="translate-page">
            
            <div className="translate-container">
                
                <div className="upload-container">
                    {/* <div className="upload-header">
                        <h3>{t('introHeader')}</h3>
                    </div> */}
                   
                    <div className="upload-inputs">
                        <div className="url-upload">
                            <input
                                type="text"
                                placeholder={t('pasteYoutubeLink')}
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="voice-selection-container">
                        <LanguageSelector
                            targetLanguage={targetLanguage}
                            setTargetLanguage={setTargetLanguage}
                            sourceLanguage={sourceLanguage}
                            setSourceLanguage={setSourceLanguage}
                        />
                        <div className="voice-selection">
                            <select value={selectedAudio} onChange={handleAudioChange}>
                                <option value="">{t('selectVoice')}</option>
                                {audioFiles.map(audio => (
                                    <option key={audio.file} value={audio.file}>{audio.name}</option>
                                ))}
                            </select>
                            <button onClick={handleReplayNoSound}>{t('replay')}</button>
                        </div>
                    </div>
                    {startProgress ? (
                        <div className="progress-bar-wrapper">
                            <span className="do-not-refresh-text">
                                {t('doNotRefresh', { duration: Math.round(processingDuration) })}
                            </span>
                            <ProgressBar
                                duration={processingDuration}
                                startProgress={startProgress}
                                completeImmediately={progressComplete}
                            />
                        </div>
                    ) : (
                        <button className="translate-button" onClick={handleTranslate} disabled={loading}>
                            {loading ? t('translating') : t('startTranslation')}
                        </button>
                    )}
                </div>
            </div>
            {renderZips()}
            <div></div>
            <audio ref={audioPlayerRef} controls hidden style={{ display: 'none' }}></audio>
        </div>
    );
}

export default Translate;
