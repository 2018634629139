// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database'; // Import for Firebase Realtime Database

// firebase
const firebaseConfig = {
    apiKey: "AIzaSyB9wQssJevumot2M6skAWaoPyPeQ2CWnOg",
    // authDomain: "video-translation-react.firebaseapp.com",
    authDomain: "youtranslate.me",
    databaseURL: "https://video-translation-react-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "video-translation-react",
    storageBucket: "video-translation-react.appspot.com",
    messagingSenderId: "516613387111",
    appId: "1:516613387111:web:bad40e6da16a198e3c75c2",
    measurementId: "G-RHDZDLW9F2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and other services and export them
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const database = getDatabase(app); // Export the database
