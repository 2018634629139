// src/components/processedZips.js
import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, 
    // remove 
} from 'firebase/database';

const useProcessedZip = (user, onZipUpdate, isNewZipAdded) => {
    const [zips, setZips] = useState([]);

    useEffect(() => {
        if (!user || !user.uid) return;

        const db = getDatabase();
        const zipRef = ref(db, `users/${user.uid}/processed_videos`); // Ensure this path is correct

        const unsubscribe = onValue(zipRef, (snapshot) => {
            const data = snapshot.val();
            const currentDate = new Date();
            const filteredZipArray = [];

            if (data) {
                Object.keys(data).forEach(key => {
                    const zipUrl = data[key].url;
                    if (zipUrl && zipUrl.includes('.zip')) {
                        // Extract the filename from URL
                        const filename = zipUrl.split('/').pop().split('?')[0]; // Remove any query parameters
                        const datePattern = /(\d{4}-\d{2}-\d{2})/;
                        const matches = filename.match(datePattern);
                        if (matches) {
                            const zipDate = new Date(matches[1]);
                            const timeDiff = (currentDate - zipDate) / (1000 * 3600 * 24); // Difference in days
                            if (timeDiff < 1) {
                                // Format title by removing date and extension
                                const formattedTitle = filename.replace(/_\d{4}-\d{2}-\d{2}\.zip$/, '').replace(/_/g, ' ');
                                // If less than 2 days old, add to filtered array
                                filteredZipArray.push({
                                    id: key,
                                    url: zipUrl,
                                    title: formattedTitle || 'Zip Archive'
                                });
                            } 
                            // else {
                            //     // If 5 or more days old, remove from database
                            //     remove(ref(db, `users/${user.uid}/processed_videos/${key}`));
                            // }
                        }
                    }
                });
            }

            console.log("Filtered zipArray:", filteredZipArray);  // Log to check the filtered array
            setZips(filteredZipArray.slice(-2)); // Set the state to the last three valid zips
            if (filteredZipArray.length > 0) {
                onZipUpdate(filteredZipArray);
                isNewZipAdded();
            }
        });

        return () => unsubscribe(); // Cleanup on unmount
    }, [user, onZipUpdate, isNewZipAdded]);

    return zips;
};

export default useProcessedZip;
