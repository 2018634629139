import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import './SignOut.css';

function SignOut() {
    const [error, setError] = useState('');  // State to store any error message
    const [isSigningOut, setIsSigningOut] = useState(false);  // State to manage loading state

    const signOutUser = async () => {
        setError('');  // Reset any previous error messages
        setIsSigningOut(true);  // Indicate the start of the sign-out process
        try {
            await signOut(auth);
            // Optionally, you can provide user feedback here as well, e.g., a success message
            setIsSigningOut(false);  // Reset signing out state on success
        } catch (error) {
            console.error('Sign Out Error', error);
            setError('Failed to sign out. Please try again.');  // Display a user-friendly error message
            setIsSigningOut(false);  // Reset signing out state on error
        }
    };

    return (
        <div>
            <button className="sign-out-button" onClick={signOutUser} disabled={isSigningOut}>
                {isSigningOut ? 'Signing Out...' : 'Sign Out'}
            </button>
            {error && <p className="error-message">{error}</p>} 
        </div>
    );
}

export default SignOut;
