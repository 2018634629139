// IframeCarousel.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function IframeCarousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 2,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
<div style={{  marginTop: "50px",  padding:"50px" }}  className="video-slider-container">
   {/* <div style={{  marginBottom: "30px" }}> <h3 className="video-slider-label" style={{ color: 'white', marginTop: "0px" }}>Examples of translated videos</h3></div> */}
        <Slider {...settings} >

            {/* <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/0fYiD1LJhSU?cc_load_policy=1&cc_lang_pref=pl&si=dSYCHQVfGBG2-qbC" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen  loading="lazy"></iframe>
            </div> */}

            <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/4FcTx7TS1Ms?cc_load_policy=1&cc_lang_pref=fr&si=blvqKYHbM1-4x9QP" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div>
            
            
            <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/k4N-Z5AXsV8?cc_load_policy=1&cc_lang_pref=fr&si=kQ3RCszWR6pdwNLh" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div>

            <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/vTga7mE2xwg?cc_load_policy=1&cc_lang_pref=en&si=7VYQNPeXTpt5Yeu4" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div>
            {/* <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/aFY6xEoXyhw?cc_load_policy=1&cc_lang_pref=jp&si=vywTAuD7m5wa0p8-" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div> */}
            <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/T5stUplDS2o?cc_load_policy=1&cc_lang_pref=ar&si=g-QH_xG8P-rxprB3" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen  loading="lazy"></iframe>
            </div>

            {/* <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/1gcW8W8ucQA?cc_load_policy=1&cc_lang_pref=hi&si=Nzj6Gh15J-o173U8" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen  loading="lazy"></iframe>
            </div>
          */}
           
            {/* <div class="video-container">
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/lH0CO7wsODo?cc_load_policy=1&cc_lang_pref=ge&si=xFq1M4wXDn7I4r1r" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div> */}
            
            {/* <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/P8UOOpLgDak?cc_load_policy=1&cc_lang_pref=fr&si=lb0W_VgRjhvo35_k" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen  loading="lazy"></iframe>
            </div> */}
            {/* <div>
                <iframe class="video-iframe" width="560" height="315" src="https://www.youtube.com/embed/svn1glsX9m0?cc_load_policy=1&cc_lang_pref=tr&si=5COcvmUjyFiIf6oa" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div> */}
            {/* <div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vEeUjU9-ZeA?cc_load_policy=1&cc_lang_pref=fr&si=5VzT_DL6Ocv507zR" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen loading="lazy"></iframe>
            </div> */}
           
       
       
        </Slider>
        </div>
    );
}

export default IframeCarousel;