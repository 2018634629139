import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase-config';
import SignIn from './SignIn';
import SignOut from './SignOut';
import Translate from './Translate';
import { listenToBalance } from './components/userBalance';
import './App.css';
import IframeCarousel from './components/IframeCarousel';
import TopUpBalance from './components/topUpBalance';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import './i18n';
import './Motivation.css';

function App() {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0);
  const { t, i18n } = useTranslation();
  const additionalContentRef = useRef(null);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleAuthState = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          console.log(t('userSignedInRedirect'), result.user);
          setUser(result.user);
          listenToBalance(result.user.uid, setBalance);
        }
      } catch (error) {
        console.error(t('errorRetrievingRedirectResult'), error);
      }

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(t('userIsSignedIn'), user);
          setUser(user);
          listenToBalance(user.uid, setBalance);
        } else {
          console.log(t('noUserSignedIn'));
          setUser(null);
          setBalance(0);
        }
      });

      return () => unsubscribe();
    };

    handleAuthState();
  }, [t]);

  const client_id = "AboksERa_Kd4qapxcQehGkoxbvXg1oSgDyfFNa1T3WdWRJHTtTQGLZA3N0UbMoul3jNuDYzuc4RMRUU1";

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const translation = t('motivationSubheadline', {
    defaultValue: "with multilingual and the most affordable AI voiceover translation service in the market hands down. The cost of translating your videos will easily be made up for by the amount of newly gained international audience. No hidden costs. You only pay for what you use."
  });
  
  const parts = translation.split(t('mostAffordableHighlight', { defaultValue: "the most affordable" }));
  

  return (
    <div className="App" id="Home">
      <nav className="navbar">
        <div className="logo-nav-container">
          <div className="logo-play-button">
            <img src="/images/logo.png" alt={t('altLogo', { defaultValue: "YouTranslate Logo" })} />
          </div>
          <a href="#videos-demo" className="about-link">{t('howTo', { defaultValue: "How to" })}</a>
          <a href="#pricing" className="about-link">{t('navPricing', { defaultValue: "Pricing" })}</a>
          <select onChange={(e) => changeLanguage(e.target.value)} defaultValue={i18n.language}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="ar">عربي</option>
            <option value="ru">Русский</option>
            <option value="in">Bahasa Indonesia</option>
            <option value="ur">اردو</option>
          </select>
        </div>

        <div>
          {user ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '20px', marginBottom: '8px' }}>
                <PayPalScriptProvider options={{ "client-id": client_id }}>
                  <TopUpBalance user={user} client_id={client_id} />
                </PayPalScriptProvider>
              </div>

              <span>{t('helloUser', { name: user.displayName, defaultValue: "Hello, {{name}}" })}</span>
              <span style={{ marginLeft: '20px', marginRight: '30px', fontSize: '15px' }}>{t('balance', { balance, defaultValue: "Balance: {{balance}}" })}</span>
              <SignOut />
            </div>
          ) : (
            <SignIn />
          )}
        </div>
      </nav>

      <div className="middle-content">
        {!user && (
        <section className="motivation-section">
            <div className="motivation-content">
              <div className="motivation-text">
                <h2 className="green-text">
                  {t('motivationHeadlinePart1', { defaultValue: "Increase your" })}
                  <span className="youtube-text"> YouTube </span>
                  {t('motivationHeadlinePart2', { defaultValue: "subscribers number in a few clicks" })}
                </h2>

                <p>
                  {parts[0]}
                  <span className="highlight">{t('mostAffordableHighlight', { defaultValue: "the most affordable" })}</span>
                  {parts[1]}
                </p>
              </div>
              <div className="motivation-image">
                <img src="/images/motivation-image.png" alt={t('motivationImageAlt', { defaultValue: "Motivational Image" })} />
              </div>
            </div>
          </section>


        )} 

        <Translate user={user} />
        <div ref={additionalContentRef} className="additional-content">
          <div id="videos-demo">
            <IframeCarousel />
          </div>
          <div className="pricing-container" id="pricing">
            <div className="pricing-card">
              <h2>{t('audioTranslationTitle', { defaultValue: "Audio Translation" })}</h2>
              <p className="price">{t('audioTranslationPrice', { defaultValue: "$20 per hour" })}</p>
              <ul>
                <li>{t('audioTranslationDesc1', { defaultValue: "High-quality translations" })}</li>
                <li>{t('audioTranslationDesc3', { defaultValue: "Fast turnaround time" })}</li>
                <li>{t('audioTranslationDesc4', { defaultValue: "Multiple languages supported" })}</li>
                <li>{t('audioTranslationDesc5', { defaultValue: "Accurate and reliable" })}</li>
              </ul>
            </div>
            <div className="pricing-card">
              <h2>{t('enterpriseSolutionsTitle', { defaultValue: "Enterprise Solutions" })}</h2>
              <p className="price">{t('enterpriseSolutionsPrice', { defaultValue: "Contact for pricing" })}</p>
              <p>{t('enterpriseSolutionsDesc', { defaultValue: "Customized solutions for your business needs" })}</p>
              <a href="mailto:hello@youtranslate.pro">
                <button className="contact-button">{t('contactUs', { defaultValue: "Contact Us" })}</button>
              </a>
            </div>
          </div>
          <div className="terms-container" id="about">
            <h2>{t('ourMissionTitle', { defaultValue: "Our Mission" })}</h2>
            <p>{t('ourMissionDesc1', { defaultValue: "Providing high-quality translations at an affordable price." })}</p>
            <p>{t('ourMissionDesc2', { defaultValue: "Ensuring accuracy and reliability in every translation." })}</p>
            <p>{t('ourMissionDesc3', { defaultValue: "Expanding our services to support more languages and regions." })}</p>
            <h2>{t('whyChooseUsTitle', { defaultValue: "Why Choose Us" })}</h2>
            <ul>
              <li>{t('whyChooseUsItem1', { defaultValue: "Expert translators" })}</li>
              <li>{t('whyChooseUsItem2', { defaultValue: "Affordable pricing" })}</li>
              <li>{t('whyChooseUsItem3', { defaultValue: "Fast turnaround" })}</li>
              <li>{t('whyChooseUsItem4', { defaultValue: "Multiple languages" })}</li>
              <li>{t('whyChooseUsItem5', { defaultValue: "Excellent customer service" })}</li>
            </ul>
            <h2>{t('lookingForwardTitle', { defaultValue: "Looking Forward" })}</h2>
            <p>{t('lookingForwardDesc', { defaultValue: "We are constantly innovating to improve our services." })}</p>
            <h2>{t('corporatePartnershipsTitle', { defaultValue: "Corporate Partnerships" })}</h2>
            <p>{t('corporatePartnershipsDesc1', { defaultValue: "Partner with us for customized translation solutions." })}</p>
            <p>{t('corporatePartnershipsDesc2', { defaultValue: "Contact us to learn more about our corporate packages." })}</p>
            <p>{t('corporatePartnershipsDesc3', { defaultValue: "Email us at hello@youtranslate.pro for more information." })}</p>
          </div>
          <div className="terms-container" id="terms">
            <h2>{t('termsConditions', { defaultValue: "Terms & Conditions" })}</h2>
            <p><strong>{t('lastUpdated', { defaultValue: "Last Updated:" })}</strong> {t('lastUpdatedDate', { defaultValue: "January 1, 2024" })}</p>
            <p>{t('termsIntro', { defaultValue: "Please read these terms and conditions carefully before using our service." })}</p>
            <h3>{t('introduction', { defaultValue: "Introduction" })}</h3>
            <ul>
              <li>{t('introductionItem1', { defaultValue: "Welcome to YouTranslate." })}</li>
              <li>{t('introductionItem2', { defaultValue: "By accessing our service, you agree to these terms." })}</li>
            </ul>
            <h3>{t('copyrightAndPermissions', { defaultValue: "Copyright & Permissions" })}</h3>
            <ul>
              <li>{t('copyrightItem1', { defaultValue: "All content is owned by YouTranslate." })}</li> </ul>
            <h3>{t('serviceDescription', { defaultValue: "Service Description" })}</h3>
            <ul>
              <li>{t('serviceDescItem1', { defaultValue: "We provide translation services." })}</li>
              <li>{t('serviceDescItem2', { defaultValue: "Our services are subject to availability." })}</li>
            </ul>
            <h3>{t('userObligations', { defaultValue: "User Obligations" })}</h3>
            <ul>
              <li>{t('userObligationsItem1', { defaultValue: "Provide accurate information." })}</li>
              <li>{t('userObligationsItem2', { defaultValue: "Use our services legally." })}</li>
            </ul>
            <h3>{t('limitationOfLiability', { defaultValue: "Limitation of Liability" })}</h3>
            <ul>
              <li>{t('limitationItem1', { defaultValue: "We are not liable for any damages." })}</li>
              <li>{t('limitationItem2', { defaultValue: "Use our services at your own risk." })}</li>
            </ul>
            <h3>{t('amendmentsToTerms', { defaultValue: "Amendments to Terms" })}</h3>
            <ul>
              <li>{t('amendmentsItem1', { defaultValue: "We may update these terms periodically." })}</li>
            </ul>
          </div>
          <div className="terms-container" id="privacy">
            <h2>{t('privacyPolicyTitle', { defaultValue: "Privacy Policy" })}</h2>
            <p>{t('privacyPolicyIntro', { defaultValue: "We value your privacy and are committed to protecting your information." })}</p>
            <h3>{t('informationWeCollectTitle', { defaultValue: "Information We Collect" })}</h3>
            <p>{t('informationWeCollectDesc', { defaultValue: "We collect personal information to provide our services." })}</p>
            <h3>{t('logDataTitle', { defaultValue: "Log Data" })}</h3>
            <p>{t('logDataDesc', { defaultValue: "We collect log data for analytics and improvement." })}</p>
            <h3>{t('personalInformationTitle', { defaultValue: "Personal Information" })}</h3>
            <p>{t('personalInformationDesc', { defaultValue: "We store your personal information securely." })}</p>
            <h3>{t('securityOfYourInformationTitle', { defaultValue: "Security of Your Information" })}</h3>
            <p>{t('securityOfYourInformationDesc', { defaultValue: "We take measures to protect your data." })}</p>
            <h3>{t('childrensPrivacyTitle', { defaultValue: "Children's Privacy" })}</h3>
            <p>{t('childrensPrivacyDesc', { defaultValue: "We do not knowingly collect data from children under 13." })}</p>
            <h3>{t('cookiePolicyTitle', { defaultValue: "Cookie Policy" })}</h3>
            <p>{t('cookiePolicyDesc', { defaultValue: "We use cookies to improve your experience." })}</p>
            <h3>{t('amendmentsToPrivacyPolicyTitle', { defaultValue: "Amendments to Privacy Policy" })}</h3>
            <p>{t('amendmentsToPrivacyPolicyDesc', { defaultValue: "We may update this policy periodically." })}</p>
            <h3>{t('yourRightsTitle', { defaultValue: "Your Rights" })}</h3>
            <p>{t('yourRightsDesc', { defaultValue: "You have the right to access and control your data." })}</p>
            <h3>{t('contactPrivacyTitle', { defaultValue: "Contact Us" })}</h3>
            <p>{t('contactPrivacyDesc', { defaultValue: "If you have any questions, contact us at privacy@youtranslate.pro." })}</p>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <a href="#pricing" className="about-link">{t('navPricing', { defaultValue: "Pricing" })}</a>
            <a href="#about" className="about-link">{t('navAbout', { defaultValue: "About" })}</a>
            <a href="#terms" className="about-link">{t('navTerms', { defaultValue: "Terms" })}</a>
            <a href="#privacy" className="about-link">{t('navPrivacy', { defaultValue: "Privacy" })}</a>
          </div>
        </div>
        © 2024 YouTranslate. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
